import { ref } from '@vue/composition-api'

import axiosClient from '@/helpers/axios'
import useAlertNotification from '@/services/notification/useAlert'

export default function useTheComments() {
  const { errorToast, successToast } = useAlertNotification()
  const comment = ref([])
  const loader = ref(false)
  const commentProcess = ref(false)
  const commentSuccess = ref(false)
  const comments = ref([])
  const errors = ref('')

  // Liste des comments
  const getTheComments = async () => {
    loader.value = true
    await axiosClient.get('/note_comments').then(response => {
      if (response.data.success === true) {
        loader.value = false
        comments.value = response.data.data
      }
    })
      .catch(() => {
        loader.value = false
      })
  }

  // Obtenir une comment
  const getComment = async id => {
    const response = await axiosClient.get(`/note_comments/${id}`)
    comment.value = response.data.data
  }
  // Ajouter une comment
  const createComment = async data => {
    errors.value = ''
    commentProcess.value = true
    await axiosClient.post('/note_comments', data)
      .then(response => {
        if (response.data.success === true) {
          successToast('Enregistrement', response.data.message)
          commentSuccess.value = true
          commentProcess.value = false
          getTheComments()
        }
      })
      .catch(error => {
        commentProcess.value = false
        commentSuccess.value = false
        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errorToast('Oups! Erreur', error.response.data.message)
          errors.value = error.response.data.errors
        }
      })
  }

  // Modifier une comment
  const updateComment = async data => {
    errors.value = ''
    commentProcess.value = true
    await axiosClient.patch(`/note_comments/${data.id}`, data)
      .then(response => {
        if (response.data.success === true) {
          successToast('Modification', response.data.message)
          commentSuccess.value = true
          commentProcess.value = false
          getTheComments()
        }
      })
      .catch(error => {
        commentProcess.value = false
        commentSuccess.value = false

        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errorToast('Oups! Erreur', error.response.data.message)
          errors.value = error.response.data.errors
        }
      })
  }

  return {
    errors,
    comment,
    comments,
    getTheComments,
    commentProcess,
    createComment,
    updateComment,
    getComment,
    loader,
    commentSuccess,
  }
}
